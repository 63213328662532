<template>
    <div class="featured-properties">
        <div class="title-container" v-bind:style="styleObject">
            <h2>
                {{title}}
            </h2>
            <hr />
        </div>
        <div class="slideshow-container">
            <vueper-slides
                :fixed-height="true"
                :slide-ratio="1 / 4"
                :dragging-distance="70"
                class="featured-slides"
                :arrows="window.width > 641 ? true : false"
                bullets-outside
                prevent-y-scroll>
                <vueper-slide
                    v-for="(property, i) in data"
                    :key="i"
                    :image="property.DisplayImage ? url+ property.DisplayImage.url : '/images/placeholder.png'"
                    class="slide"
                    >
                    <template v-slot:content>
                        <div class="slide-content" :class="{'align-right': align === 'right'}">
                            <span class="tag" :class="property.Tag">
                                {{property.Tag | startCase}}
                            </span>
                            <div class="details">
                                <h2>
                                    {{property.Title}}
                                </h2>
                                <div class="price-link">
                                    <p>
                                        {{property.Price | priceFormat}} <span v-if="property.Lease"> / mo.</span> /
                                    </p>
                                    <router-link :to="`/property/${property.Slug}`">
                                         View Details
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </template>
                </vueper-slide>
            </vueper-slides>
        </div>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'

export default {
    name: "FeaturedProperties",
    components: { VueperSlides, VueperSlide},
    props: ['title','align', 'bgColor', 'data'],
    data(){
        return{
            url: 'https://strapi.uptowncreative.io',
            styleObject: {
                background: this.bgColor ?  this.bgColor : 'none',
            },
            window: {
                width: 0,
                height: 0
            },
        }
    },
    computed: {
        adaptiveHeight: function(){
            if(this.window.width < 641){
                return '400px';
            }
            if (this.window.height > 800){
                return '100vh';
            } else {
                return this.window.height + 'px';
            }
        }
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style lang="scss" scoped>
.title-container{
    padding: 4rem 0;
    h2{
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 3px;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 1.5rem;
    }
    hr{
        display: block;
        height: 1px;
        border: 0;
        width: 50%;
        border-top: 1px solid rgba(255,255,255,0.25);
    }
}
.slideshow-container{
    margin-bottom: 151px;
}
.slide{
    display: flex;
    flex-direction: column;
    justify-content: center;
    &::before{
        content: ""; // ::before and ::after both require content
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-image: linear-gradient(#000, #000);
        opacity: .2;
    }
}
.slide-content{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 4rem;
    z-index: 5;
    &.align-right{
        margin-right: 4rem;
        align-items: flex-end;
        text-align: right;
        .price-link{
            justify-content: flex-end;
        }
    }
    .tag{
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 3px;
        color: #000;
        padding: 5px 20px;
    }
    h2{
        margin: 0;
        font-weight: 100;
        font-size: 65px;
        letter-spacing: 2px;
        color: #fff;
        text-transform: uppercase;
        line-height: 40px;
        margin-top: 25px;
    }
    a{
        font-size: 18px;
        letter-spacing: 3px;
        opacity: 0.6;
        text-transform: uppercase;
        font-weight: 500;
        &:hover{
            opacity: 1;
        }
    }
    .price-link{
        display: flex;
        align-items: center;
        p{
            font-size: 18px;
            letter-spacing: 3px;
            text-transform: uppercase;
            font-weight: 500;
            opacity: 1;
            color: #fff;
            margin-right: 5px;
        }
        a{
            display: block;
        }
    }

}
@media (max-width: $mobile) { 
    .slide-content{
        margin-left: 2rem;
        justify-content: flex-end;
        height: 80%;
        &.align-right{
            justify-content: flex-end;
            margin-right: 2rem;
            .price-link{
                justify-content: flex-end;
            }
        }
        h2{
            line-height: 1;
            font-size: 40px;
        }

    }
}
</style>

<style lang="scss">
.featured-slides{
    .vueperslides__arrow{
        top: unset;
        bottom: 0;
        z-index: 5;
    }
    .vueperslides__bullets{
        margin-top: 25px
    }
    .vueperslides__bullet .default {
        background-color: rgba(146, 140, 140, 1);
        border: none;
        box-shadow: none;
        transition: 0.3s;
        width: 6px;
        height: 6px;
    }
    .vueperslides__bullet--active .default {
        background-color: #fff;
    }
}


.slideshow-container{
    .vueperslide--clone{
        display: flex;
        flex-direction: column;
        justify-content: center;
        &::before{
            content: ""; // ::before and ::after both require content
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background-image: linear-gradient(#000, #000);
            opacity: .2;
        }
        .slide-content{
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-left: 4rem;
            z-index: 5;
            &.align-right{
                margin-right: 4rem;
                align-items: flex-end;
                text-align: right;
            }
            .tag{
                font-weight: 600;
                font-size: 12px;
                letter-spacing: 3px;
                color: #000;
                padding: 5px 20px;
            }
            h2{
                margin: 0;
                font-weight: 100;
                font-size: 65px;
                letter-spacing: 2px;
                color: #fff;
                text-transform: uppercase;
            }
            a{
                font-size: 18px;
                letter-spacing: 3px;
                opacity: 0.6;
                text-transform: uppercase;
                font-weight: 500;
                &:hover{
                    opacity: 1;
                }
            }

        }
    }

    .vueperslides--fixed-height {
        height: 100vh;
    }
}

@media (min-height: 900px) {
    .vueperslides--fixed-height {
        height: 900px;
    }
}
@media (max-width: $mobile) { 
    .slide-content{
        margin-left: 2rem;
        justify-content: space-between;
        height: 80%;
        &.align-right{
            margin-right: 2rem;
        }
        h2{
            line-height: 1;
            font-size: 40px;
        }

    }
    .slideshow-container{
        .vueperslides--fixed-height{
            height: 400px;
        }
    }
}
</style>